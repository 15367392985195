import Tabs from './tabs.js';
import LinksTracker from './linkstracker';
import lazyframe from 'lazyframe';

class Global {
  constructor(options) {
    var defaults = {};
    this.params = Object.assign({}, defaults, options || {});
    this.run();
  }

  run() {
    this.tabs();
    new LinksTracker();
    this.lazyLoadVideos();
    this.sharingTools();
  }

  menu() {
    var burgers = document.querySelectorAll('.rich-menu');
    burgers.forEach((burger) => {
      burger.addEventListener('click', function (event) {
        event.preventDefault();
        this.classList.toggle('is-open');
        document.body.classList.toggle('menu-opened');
      });
    });
  }

  tabs() {
    var i, tabs = document.querySelectorAll('.tabs');
    for (i = 0; i < tabs.length; i++) {
      new Tabs({
        container: tabs[i]
      });
    }
  }

  lazyLoadVideos() {
    lazyframe('.lazyframe', {
      autoplay: false
    });
  }

  sharingTools() {

    window.shareToMessenger = this.shareToMessenger;
    window.isMobile = this.isMobile;
    window.copyItemUrl = this.copyItemUrl;

    var toolbar = document.querySelector('.sharing-top-sticky');
    if (!toolbar) {
      return;
    }
    window.addEventListener('scroll', (e) => {
      if (window.scrollY >= 600) {
        toolbar.classList.add('visible');
      } else {
        toolbar.classList.remove('visible');
      }
    });
    var toggler = toolbar.querySelector('.share-toggler');
    if (toggler) {
      toggler.addEventListener('click', (e) => {
        e.preventDefault();
        toolbar.classList.toggle('can-share');
      });
    }

  }

  shareToMessenger(url) {
    if (window.isMobile()) {
      window.location.href = 'fb-messenger://share/?app_id=1442666465793923&link=' + url;
    } else {
      window.open('https://www.facebook.com/dialog/send?app_id=1442666465793923&link=' + url + '&redirect_uri=' + url, '_blank');
    }
  }

  isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
      return true;
    }

    if (/android/i.test(userAgent)) {
      return true;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
    }

    return false;
  }

  copyItemUrl(element) {

    if (!navigator.clipboard) {
      return;
    }

    const url = element.dataset.url;

    if (!url) {
      return;
    }

    navigator.clipboard.writeText(url).then(
      () => {
        element.classList.add('copied');
        window.setTimeout(() => {
          element.classList.remove('copied');
        }, 5000);

      }, () => {
        console.log('Copy URL failed');
      }
    );

  }

}

export {
  Global as
    default
};
